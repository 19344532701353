import { gql } from '@apollo/client';
import { WordPressBlocksViewer } from '@faustwp/blocks';
import { FaustTemplate, flatListToHierarchical } from '@faustwp/core';
import dynamic from 'next/dynamic';
import { GetPageQuery, GetPostQuery } from '../__generated__/graphql';
import {
	AcfAccordionDisplayFragment,
	AcfCountUpDisplayFragment,
	AcfHeroBannerFragment,
	AcfLogoSliderFragment,
	AcfProductIconDisplayFragment,
	AcfStepDisplayFragment,
	CoreButtonBlockFragment,
	CoreButtonsBlockFragment,
	CoreColumnBlockFragments,
	CoreColumnsBlockFragment,
	CoreGroupBlockFragment,
	CoreImageBlockFragment,
	CoreParagraphFragment,
	GravityformsFormFragment,
} from '../wp-blocks/fragments';
import PostBanner from '../components/PostFeed/components/PostBanner';
import PostPageBanner from '../components/PostPageBanner';

// Dynamically import the SiteWrapper component
const SiteWrapper = dynamic(() => import('../components/SiteWrapper'), {
	ssr: false, // Set to false if the component should not be server-side rendered
});

const Single: FaustTemplate<GetPostQuery> = props => {
	if (props.loading) {
		return <>Loading page data...</>;
	}

	if (!props.data) {
		console.error('props.data is undefined');
		return <>Error: Data is missing</>;
	}

	if (!props.data.post || !props.data.post.editorBlocks) {
		return <>Error: Page data is missing or undefined</>;
	}

	const { editorBlocks } = props.data.post;
	const hierarchicalBlocks = flatListToHierarchical(editorBlocks, {
		childrenKey: 'innerBlocks',
	});

	return (
		<SiteWrapper siteProps={props.data}>
			<PostPageBanner
				date={props.data.post.date}
				featuredImage={props.data.post.featuredImage.node}
				content={props.data.post.postSettings.topContent}
			/>
			<WordPressBlocksViewer blocks={hierarchicalBlocks} />
		</SiteWrapper>
	);
};

Single.query = gql`
	${CoreParagraphFragment}
	${CoreGroupBlockFragment}
	${CoreColumnsBlockFragment}
	${CoreColumnBlockFragments}
	${CoreImageBlockFragment}
	${CoreButtonsBlockFragment}
	${CoreButtonBlockFragment}

	query GetPost($databaseId: ID!, $asPreview: Boolean = false) {
		post(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
			title
			date
			editorBlocks(flat: true) {
				name
				__typename
				renderedHtml
				id: clientId
				parentId: parentClientId
				...CoreParagraphFragment
				...CoreGroupBlockFragment
				...CoreColumnsBlockFragment
				...CoreColumnBlockFragments
				...CoreImageBlockFragment
				...CoreButtonsBlockFragment
				...CoreButtonBlockFragment
				${AcfHeroBannerFragment}
				${AcfStepDisplayFragment}
				${AcfCountUpDisplayFragment}
				${AcfLogoSliderFragment}
				${AcfAccordionDisplayFragment}
				${AcfProductIconDisplayFragment}
				${GravityformsFormFragment}
			}
			seo {
				canonical
				title
				metaDesc
				focuskw
				schema {
					pageType
					articleType
					raw
				}
			}

			saswpSchema {
				json_ld
			}
			
			postSettings {
				topContent
			}

			featuredImage {
				node {
					id
					sourceUrl
					altText
					mediaDetails {
							width
							height
					}
					sizes(size: MEDIUM)
				}
			}
		}
		generalSettings {
			title
			description
		}
		primaryMenuItems: menuItems(where: { location: PRIMARY }, last: 50) {
			nodes {
				id
				uri
				path
				label
				parentId
				cssClasses
				url
			}
		}
		footerMenuItems: menuItems(where: { location: FOOTER }) {
			nodes {
				id
				uri
				path
				label
				parentId
				cssClasses
				menu {
					node {
						name
					}
				}
			}
		}
		productMenuItems: menuItems(where: { location: PRODUCT }) {
			nodes {
				id
				uri
				path
				label
				parentId
				cssClasses
				menu {
					node {
						name
					}
				}
				url
			}
		}
		cbaSiteSettings {
			cbaSiteSettingsFields {
				ctaText
				ctaHeader
				ctaContent
				ctaButtonLink {
					nodes {
						uri
						link
					}
				}
				ctaButtonText
				productMenuTitle
				phoneNumber
				address
				ctaLink {
					nodes {
						uri
						link
					}
				}
				socialMedia {
					link
					platform
				}
				copyright
			}
		}
	}
`;

Single.variables = ({ databaseId }, ctx) => {
	return {
		databaseId,
		asPreview: ctx?.asPreview,
	};
};

export default Single;
